import { useEffect, useState } from 'react';
import './add-professionalpage-basic-info.css';
import ProfessionalPageBasicInfoImage from '../../../../assets/professional-page-1.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropertyApiService from '../../../../data/api/services/property/property-api-service';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import FormFieldError from '../../../../components/form-field-error/form-field-error';
import ValidationError from '../../../../common/validation-error';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import LocationApiService from '../../../../data/api/services/location/location-api-service';
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "react-bootstrap";
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';

import { SaveLocationPayload } from '../../../../data/api/services/location/location-payload';
const AddProfessioanlPageBasicinfo = ({ professionalPageFormData, onNextCallback, onPreviousCallback }) => {

    const locationApiService = new LocationApiService();
    const emailMaxLength = 50;
    const nameMaxLength = 100;
    const minLength = 0;
    const maxLengths = 200;
    const [nameText, setNameText] = useState('');
    const [nickNameText, setNickNameText] = useState('');
    const [aboutUs, setAboutUs] = useState('');

    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [registerType, setRegisterType] = useState('PAGE_USER');

    const propertyApiService = new PropertyApiService();
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);
    const [nameValidationErrorMsg, setNameValidationErrorMsg] = useState(null);
    const [emailValidationErrorMsg, setEmailValidationErrorMsg] = useState(null);
    const [addressValidationErrorMsg, setAddressValidationNameErrorMsg] = useState(null);
    const [processing, setProcessing] = useState(false);
    const tabNumber: number = 1;
    const [showAddLocationDialog, setShowAddLocationDialog] = useState(false);
    const handleShowAddLocationDialog = () => setShowAddLocationDialog(true);
    const handleCloseAddLocationDialog = () => setShowAddLocationDialog(false);
    const [noResults, setNoResults] = useState(false);
    const [locationName, setLocationName] = useState("");
    const [locationNameValidationError, setLocationNameValidationError] = useState(false);
    const [pinCodeSearchLoading, setPinCodeSearchLoading] = useState(false);
    const [pinCodeData, setPinCodeData] = useState<any>([]);
    const [selectedPinCode, setSelectedPinCode] = useState<any>([]);
    const [pinCodeValidationErrorMsg, setPinCodeValidationErrorMsg] = useState(false);

    const validateBasicInfoFormData = (): void => {
        let errors: any[] = [];
        if (!nameText) {
            let error: any = {};
            error.validationCode = "nameText";
            error.message = 'Name required';
            errors.push(error);

        } else if (nameText.length < 3) {
            let error: any = {};
            error.validationCode = "nameText";
            error.message = 'Name should be at least 3 characters long';
            errors.push(error);
        }

        if (!email) {
            let error: any = {};
            error.validationCode = "email";
            error.message = 'Email required';
            errors.push(error);
        }

        if (!address) {
            let error: any = {};
            error.validationCode = "address";
            error.message = 'Address required';
            errors.push(error);
        }

        if (selectedLocation.length === 0) {
            let error: any = {};
            error.validationCode = "location";
            error.message = 'Location required';
            errors.push(error);
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const submitBasicInfoFormData = async () => {
        try {
            validateAndSaveBasicInfoFormData();
            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }

    const validateAndSaveBasicInfoFormData = () => {
        try {
            validateBasicInfoFormData();
            if (professionalPageFormData.has('name')) {
                professionalPageFormData.delete('name');
            }
            professionalPageFormData.append('name', nameText);

            if (professionalPageFormData.has('nickName')) {
                professionalPageFormData.delete('nickName');
            }
            professionalPageFormData.append('nickName', nickNameText);

            if (professionalPageFormData.has('aboutUs')) {
                professionalPageFormData.delete('aboutUs');
            }
            professionalPageFormData.append('aboutUs', aboutUs);
            if (professionalPageFormData.has('email')) {
                professionalPageFormData.delete('email');
            }
            professionalPageFormData.append('email', email);

            if (professionalPageFormData.has('address')) {
                professionalPageFormData.delete('address');
            }

            professionalPageFormData.append('address', address);

            if (professionalPageFormData.has('registerType')) {
                professionalPageFormData.delete('registerType');
            }

            professionalPageFormData.append('registerType', registerType);

            if (professionalPageFormData.has('locationIdStr')) {
                professionalPageFormData.delete('locationIdStr');
            }
            if (selectedLocation.length > 0) {
                professionalPageFormData.append('locationIdStr', selectedLocation[0].id);
            }

        } catch (error: any) {
            throw error;
        }
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;

            if (errorCode === 'nameText') {
                setNameValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'email') {
                setEmailValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'address') {
                setAddressValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'location') {
                setLocationValidationNameErrorMsg(errorMsg);
            }
        }
    }

    const handleNameChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setNameText(inputValue);
            if (inputValue.length > 3) {
                setNameValidationErrorMsg(null);
            }
        }
    };

    const renderMenuItemChildren = (option, props, index) => {
        return (
            <div key={index}>
                <span style={{ color: 'black', marginRight: '5px' }}>{option.name}</span>
                <Tooltip title={option.isVerified ? 'Verified Location' : 'Location Not Verified'}>
                    <VerifiedIcon 
                        sx={{
                            color: option.isVerified ? '#0d6efd' : 'grey',  
                            fontSize: '15px', 
                        }} 
                    />
                </Tooltip>
                {' | '}
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName}</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.talukaName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.districtName}</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>
            </div>
        );
    };

    const handleNickNameChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setNickNameText(inputValue);
        }
    };
    const handleAboutUsChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setAboutUs(inputValue);
        }
    };

    const handleEmailChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setEmail(inputValue);
            if (inputValue.length >= 5) {
                setEmailValidationErrorMsg(null);
            }
        }
    };

    const handleAddressChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setAddress(inputValue);
            if (inputValue.length >= 1) {
                setAddressValidationNameErrorMsg(null);
            }
        }
    };

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        setLocationData(data);
        if (q.length > 3 && data.length === 0) {
            setNoResults(true);
        } else {
            setNoResults(false);
        }
    };

    const onLocationSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedLocation(e);
        }
        setLocationValidationNameErrorMsg('');
    };

    const highlightBasicInformationFormData = async () => {
        let formData: FormData = professionalPageFormData;
        if (formData.get('name')) {
            setNameText(formData.get('name').toString());
        }
        if (formData.get('nickName')) {
            setNickNameText(formData.get('nickName').toString());
        }
        if (formData.get('aboutUs')) {
            setAboutUs(formData.get('aboutUs').toString());
        }
        if (formData.get('email')) {
            setEmail(formData.get('email').toString());
        }
        if (formData.get('address')) {
            setAddress(formData.get('address').toString());
        }
        if (formData.get('locationIdStr')) {
            const locationIdObj = formData.get('locationIdStr');
            const selectedLocationData = await locationApiService.getLocationById(locationIdObj);
            setSelectedLocation([selectedLocationData]);
        }
    }

    const clearSelectedLocation = () => {
        setSelectedLocation([]);
    };

    const handleLocationNameChange = (value: any) => {
        setLocationName(value);
        setLocationNameValidationError(false);
    };

    const searchPinCode = async (pinCode: any) => {
        setPinCodeSearchLoading(true);
        let data = await locationApiService.searchPinCode(pinCode);
        console.log(data);
        setPinCodeSearchLoading(false);
        setPinCodeData(data);
    };

    const onPinCodeSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedPinCode(e);
            setPinCodeValidationErrorMsg(e.length === 0);
        }
    };

    const clearSelectedPincode = () => {
        setSelectedPinCode([]);
    };

    const submitLocation = async () => {
        try {
            if (validateSaveLocation()) {
                let payload: SaveLocationPayload = {
                    name: locationName,
                    countryId: selectedPinCode[0].countryId,
                    stateId: selectedPinCode[0].stateId,
                    districtId: selectedPinCode[0].districtId,
                    talukaId: selectedPinCode[0].talukaId,
                    postOfficeId: selectedPinCode[0].postOfficeId,
                    pinCodeId: selectedPinCode[0].id,
                    locationPriorityId: '',
                    latitude: selectedPinCode[0].latitude,
                    longitude: selectedPinCode[0].longitude,
                    isVerified: false,
                    approvedBySuperAdmin: false
                };
                let data = await locationApiService.saveLocation(payload);
                console.log(data);
                const locationByIdData = await locationApiService.getLocationById(data.id);
                setSelectedLocation([locationByIdData]);
                setLocationData([locationByIdData]);
                handleCloseAddLocationDialog();
                setLocationName("");
                setSelectedPinCode([]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validateSaveLocation = () => {
        let isFormValid = true;
        if (!locationName) {
            setLocationNameValidationError(true);
            isFormValid = false;
        }
        if (selectedPinCode.length == 0) {
            setPinCodeValidationErrorMsg(true);
            isFormValid = false;
        }
        return isFormValid;
    }

    const renderMenuForPinCode = (option, props, index) => {
        return (
            <div key={index}>
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName} (P.O.)</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.postOfficeData.talukName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.postOfficeData.talukaData.districtName} (Dist)</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.postOfficeData.talukaData.districtData.stateData.abbreviation}</span>
            </div>
        );
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        highlightBasicInformationFormData();
    }, []);

    return <>
        <section className="d-flex justify-content-center pb-0">
            <Row className="container">
                <Col lg={6} className="d-none d-lg-flex align-self-center">
                    <img src={ProfessionalPageBasicInfoImage} className='h-100 w-100' alt="google-bussiness" ></img>
                </Col>
                <Col lg={6} className="mt-3">
                    <Row className="g-3">
                        <Col lg={12} className="">
                            <h3 className="text-center">Start building your Business Profile</h3>
                        </Col>
                        <Col lg={12} className="">
                            <span className='fs-5'>This will help you get discovered by customers</span>
                        </Col>
                        <Col lg={12}>
                            <div className={`custom-input-field ${nameValidationErrorMsg ? 'is-invalid' : ''}`}>
                                <input type="text" placeholder=" "
                                    value={nameText}
                                    maxLength={nameMaxLength}
                                    onChange={handleNameChange} />
                                <span className="fw-bold custom-input-field-placeholder">Your Name</span>
                            </div>
                            {nameValidationErrorMsg &&
                                <div className="invalid-feedback px-2 m-0">
                                    {nameText.length === 0
                                        ? 'Your Name required'
                                        : 'Name must be longer than 3 characters'}
                                </div>
                            }
                            <div className="below-text d-flex justify-content-between mt-1 m-0 px-2">
                                <span>3 characters required:</span>
                                <span>{minLength + nameText.length}/100</span>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="custom-input-field">
                                <input type="text"
                                    placeholder=" "
                                    value={nickNameText}
                                    minLength={minLength}
                                    maxLength={nameMaxLength}
                                    onChange={handleNickNameChange} />
                                <span className="fw-bold custom-input-field-placeholder">Your Nick Name</span>
                            </div>
                            <div className="below-text d-flex justify-content-between mt-1 m-0 px-2">
                                <span>5 characters required:</span>
                                <span>{minLength + nickNameText.length}/100</span>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className={`custom-input-field ${emailValidationErrorMsg ? 'is-invalid' : ''}`}>
                                <input type="text"
                                    placeholder=" "
                                    value={email}
                                    minLength={minLength}
                                    maxLength={emailMaxLength}
                                    onChange={handleEmailChange} />
                                <span className="fw-bold custom-input-field-placeholder">Your Email</span>
                            </div>
                            {emailValidationErrorMsg && <div className="invalid-feedback px-2 m-0">Your Email required</div>}
                            <div className="below-text d-flex justify-content-between mt-1 m-0 px-2">
                                <span>5 characters required:</span>
                                <span>{minLength + email.length}/50</span>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className={`custom-input-field ${addressValidationErrorMsg ? 'is-invalid' : ''}`}>
                                <input type="text"
                                    placeholder=" "
                                    value={address}
                                    onChange={handleAddressChange} />
                                <span className="fw-bold custom-input-field-placeholder">Your Address</span>
                            </div>
                            {addressValidationErrorMsg && <div className="invalid-feedback px-2 m-0">Your Address required</div>}
                        </Col>
                        <Col lg={12}>
                            <div className="custom-input-field d-flex">
                                <textarea
                                    className=""
                                    rows={3}
                                    style={{ resize: 'none' }}
                                    value={aboutUs}
                                    maxLength={maxLengths}
                                    onChange={handleAboutUsChange}
                                    placeholder=" ">
                                </textarea>
                                <span className="fw-bold custom-input-field-placeholder">About Us</span>
                            </div>
                            <div className="below-text d-flex justify-content-between mt-1 m-0 px-2">
                                <span>Remaining:</span>
                                <span>{minLength + aboutUs.length}/200</span>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                {noResults && selectedLocation.length === 0 && (
                                    <div className="d-flex align-items-center mb-2" style={{ gap: '8px', color: '#0d6efd' }}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                        <span>No matching location found. <a onClick={handleShowAddLocationDialog}
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}>Add your location</a>
                                        </span>
                                    </div>
                                )}
                                <div className={`custom-input-field async-container d-flex justify-content-end align-items-center ${locationValidationErrorMsg ? 'is-invalid' : ''}`}>
                                    <Col className="close-icon align-items-center d-flex">
                                        {selectedLocation.length > 0 && (
                                            <FontAwesomeIcon icon={faTimes} onClick={clearSelectedLocation} />
                                        )}
                                    </Col>
                                    <Col>
                                        <AsyncTypeahead
                                            className="rbt-input-wrapper"
                                            id="basic-typeahead-single"
                                            labelKey="formatedLocationData"
                                            onSearch={(e) => searchLocation(e)}
                                            options={locationData}
                                            onChange={onLocationSelected}
                                            useCache={false}
                                            placeholder=" "
                                            defaultSelected={selectedLocation}
                                            isLoading={locationSearchLoading}
                                            selected={selectedLocation}
                                            renderMenuItemChildren={renderMenuItemChildren}
                                            onInputChange={(input) => {
                                                if (input.length == 0) {
                                                    setNoResults(false);
                                                }
                                            }}
                                        />
                                        <span className="fw-bold custom-input-field-placeholder">What Region is your business based in ?</span>
                                    </Col>
                                </div>
                                {locationValidationErrorMsg && <div className="invalid-feedback px-2">Location required</div>}
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="d-flex justify-content-end align-items-center p-3">
                                <div>
                                    <button
                                        disabled={processing}
                                        className="btn btn-success"
                                        type="button"
                                        onClick={submitBasicInfoFormData}
                                    >
                                        Next
                                    </button>
                                    {processing && <Spinner animation="grow" variant="warning" className="ms-2" />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal show={showAddLocationDialog} onHide={handleCloseAddLocationDialog} backdrop="static" size="lg">
                <Modal.Header className="fw-bold" closeButton>
                    <Modal.Title>Add your location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3">
                        <Col lg={12}>
                            <div className={`custom-input-field ${locationNameValidationError ? 'is-invalid' : ''}`}>
                                <input type="text"
                                    placeholder=" "
                                    value={locationName}
                                    onChange={(e) => { handleLocationNameChange(e.target.value) }} />
                                <span className="fw-bold custom-input-field-placeholder">Enter your location</span>
                            </div>
                            {locationNameValidationError && <div className="invalid-feedback px-2 m-0">Location name required</div>}
                        </Col>
                        <Col lg={12}>
                            <div>
                                <div className={`custom-input-field async-container d-flex justify-content-end align-items-center ${pinCodeValidationErrorMsg ? 'is-invalid' : ''}`}>
                                    <Col className="close-icon align-items-center d-flex">
                                        {selectedPinCode.length > 0 && (
                                            <FontAwesomeIcon icon={faTimes} onClick={clearSelectedPincode} />
                                        )}
                                    </Col>
                                    <Col>
                                        <AsyncTypeahead
                                            className="rbt-input-wrapper"
                                            id="select-pincode"
                                            labelKey="pinCode"
                                            onSearch={(e) => searchPinCode(e)}
                                            options={pinCodeData}
                                            onChange={onPinCodeSelected}
                                            useCache={false}
                                            placeholder=""
                                            defaultSelected={selectedPinCode}
                                            isLoading={pinCodeSearchLoading}
                                            selected={selectedPinCode}
                                            minLength={4}
                                            renderMenuItemChildren={renderMenuForPinCode}
                                        />
                                        <span className="fw-bold custom-input-field-placeholder">PinCode</span>
                                    </Col>
                                </div>
                                {pinCodeValidationErrorMsg && <div className="invalid-feedback px-2">PinCode required</div>}
                            </div>
                        </Col>
                        <div className="d-flex flex-column flex-lg-row justify-content-end" style={{ gap: '10px' }}>
                            <button className="btn btn-danger" onClick={handleCloseAddLocationDialog}>Cancel</button>
                            <button className="btn btn-success" onClick={submitLocation}>Submit</button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
    </>

}

export default AddProfessioanlPageBasicinfo;