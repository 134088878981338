import { useEffect, useImperativeHandle, useState } from "react";
import Footer from "../../../layouts/footer/footer";
import Header from "../../../layouts/header/header";
import { useLocation, useNavigate } from "react-router-dom";
import "./edit-location-page.css";
import { toast } from "react-toastify";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import LocationApiService from "../../../data/api/services/location/location-api-service";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { updateLocationData } from "../../../data/api/services/property/create-property-payload";
import CloseIcon from '@mui/icons-material/Close';
import FormFieldError from "../../../components/form-field-error/form-field-error";
import ValidationError from "../../../common/validation-error";
import React from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { GoLocation } from "react-icons/go";
import Tooltip from '@mui/material/Tooltip';
import VerifiedIcon from '@mui/icons-material/Verified';

const EditLocationPage = ({ deedType, propertyId, propertyFormData, onNextCallback, onPreviousCallback }) => {
    let navigate: any = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // const propertyId = searchParams.get('id');
    const propertyApiService = new PropertyApiService();
    const locationApiService = new LocationApiService();
    const [showNearByOption, setShowNearByOption] = useState(true);

    const tabNumber: number = 3;
    const [landmark, setLandmark] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [locationData, setLocationData] = useState<any>([]);
    const [propertyData, setPropertyData] = useState<any>({});
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [nearByFacilities, setNearByFacilities] = useState<any>([]);
    const [nearByFacilityData, setNearByFacilityData] = useState<any>([]);
    const [metricUnit, setMetricUnit] = useState("");
    const [address, setAddress] = useState("");
    const [showMetricUnitOption, setShowMetricUnitOption] = useState(true);
    const [showSelectedLocation, setShowSelectedLocation] = useState("");
    const [showSelectedPinCode, setShowSelectedPincode] = useState("");
    const [addressValidationErrorMsg, setAddressValidationNameErrorMsg] = useState(null);
    const [landmarkValidationErrorMsg, setLandmarkValidationNameErrorMsg] = useState(null);
    const [latitudeValidationErrorMsg, setLatitudeValidationNameErrorMsg] = useState(null);
    const [longitudeValidationErrorMsg, setLongitudeValidationNameErrorMsg] = useState(null);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);
    const [nearByFacilityValidationErrorMsg, setNearByFacilityValidationNameErrorMsg] = useState(null);
    const [nearByFacilityNameValidationErrorMsg, setNearByFacilityNameValidationNameErrorMsg] = useState(null);
    const [nearByFacilityDistanceValidationErrorMsg, setNearByFacilityDistenceValidationNameErrorMsg] = useState(null);
    const [nearByFacilityDistanceUnitValidationErrorMsg, setNearByFacilityDistenceUnitValidationNameErrorMsg] = useState(null);
    const [nearByFacilityMetricUnitValidationErrorMsg, setNearByFacilityMetricUnitValidationNameErrorMsg] = useState(null);
    const [processing, setProcessing ] = useState(false);


    const handleAddressOnChange = (value: any) => {
        setAddress(value);
        setAddressValidationNameErrorMsg('');
    }
    const handleLandMarkOnChange = (value: any) => {
        setLandmark(value);
        setLandmarkValidationNameErrorMsg('');
    }
    const handleLatitudeOnChange = (value: any) => {
        setLatitude(value);
        setLatitudeValidationNameErrorMsg('');
    }
    const handleLongitudeOnChange = (value: any) => {
        setLongitude(value);
        setLongitudeValidationNameErrorMsg('');
    }
    const renderMenuItemChildren = (option, props, index) => {
    
        return (
        <div key={index}>
           <span style={{ color: 'black', marginRight: '5px' }}>{option.name}</span>
                <Tooltip title={option.isVerified ? 'Verified Location' : 'Location Not Verified'}>
                    <VerifiedIcon 
                        sx={{
                            color: option.isVerified ? '#0d6efd' : 'grey',  
                            fontSize: '15px', 
                        }} 
                    />
                </Tooltip>
                {' | '}
        <span style={{ color: 'blue' }}>{option.pinCode}</span>
        {' | '}
        <span style={{ color: 'red' }}>{option.postOfficeName}</span>
        {' | '}
        <span style={{ color: 'green' }}>{option.talukaName}</span>
        {' | '}
        <span style={{ color: 'orange' }}>{option.districtName}</span>
        {' | '}
        <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>
        
      </div>
    );
      };
    const validateLocationFormData = (): void => {
        let errors: any[] = [];
        if (!selectedLocation) {
            let error: any = {};
            error.validationCode = "location";
            error.message = 'Location required';
            errors.push(error);
        }
        // if (!latitude) {
        //     let error: any = {};
        //     error.validationCode = "latitude";
        //     error.message = 'Latitude required';
        //     errors.push(error);
        // }
        // if (!longitude) {
        //     let error: any = {};
        //     error.validationCode = "longitude";
        //     error.message = 'Longitude required';
        //     errors.push(error);
        // }
        // if (!landmark) {
        //     let error: any = {};
        //     error.validationCode = "landmark";
        //     error.message = 'Landmark required';
        //     errors.push(error);
        // }
        // if (!address) {
        //     let error: any = {};
        //     error.validationCode = "address";
        //     error.message = 'Address required';
        //     errors.push(error);
        // }
        // if (!nearByFacilities || nearByFacilities.length === 0) {
        //     let error: any = {};
        //     error.validationCode = "nearByFacilities";
        //     error.message = 'Near By Facilities required';
        //     errors.push(error);
        // }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const submitLocationFormData = () => {
        try {
            validateAndSaveLocationFormData();
            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            if (errorCode === 'location') {
                setLocationValidationNameErrorMsg(errorMsg);
            }
            // if (errorCode === 'latitude') {
            //     setLatitudeValidationNameErrorMsg(errorMsg);
            // }
            // if (errorCode === 'longitude') {
            //     setLongitudeValidationNameErrorMsg(errorMsg);
            // }
            // if (errorCode === 'landmark') {
            //     setLandmarkValidationNameErrorMsg(errorMsg);
            // }
            // if (errorCode === 'address') {
            //     setAddressValidationNameErrorMsg(errorMsg);
            // }
            // if (errorCode === 'nearByFacilities') {
            //     setNearByFacilityValidationNameErrorMsg(errorMsg);
            // }

        }
    }

    const validateAndSaveLocationFormData = () => {
        try {
            validateLocationFormData();
            if (propertyFormData.has('longitude')) {
                propertyFormData.delete('longitude');
            }
            propertyFormData.append('longitude', longitude);

            if (propertyFormData.has('latitude')) {
                propertyFormData.delete('latitude');
            }
            propertyFormData.append('latitude', latitude);

            if (propertyFormData.has('landmark')) {
                propertyFormData.delete('landmark');
            }
            propertyFormData.append('landmark', landmark);
            if (propertyFormData.has('address')) {
                propertyFormData.delete('address');
            }
            propertyFormData.append('address', address);

            if (propertyFormData.has('locationIdStr')) {
                propertyFormData.delete('locationIdStr');
            }
            if (selectedLocation.length > 0) {
                propertyFormData.append('locationIdStr', selectedLocation[0].id);
            }

            if (propertyFormData.has('nearbyFacilityStr')) {
                propertyFormData.delete('nearbyFacilityStr');
            }
            propertyFormData.append('nearbyFacilityStr', JSON.stringify(nearByFacilities));
        } catch (error: any) {
            throw error;
        }
    }
    const updateLocation = async () => {
        try {
            validateLocationFormData();
            setProcessing(true);
            let payload: updateLocationData = {
                nearbyFacility: JSON.stringify(nearByFacilities),
                longitude: longitude,
                latitude: latitude,
                landmark: landmark,
                address: address,
                locationId: selectedLocation[0].id,
                deedTypeId: deedType
            };
            await propertyApiService.updateLocation(propertyId, payload);
            toast.success('Successfully Property Submitted', { containerId: 'TR' });
            navigate('/');

        } catch (error) {
            setProcessing(false);
            showValidationErrorInlineUI(error);
        }
    }

    const highlightLocationFormData = async () => {
        let formData: FormData = propertyFormData;
        if (formData.get('longitude')) {
            setLongitude(formData.get('longitude').toString());
        }
        if (formData.get('latitude')) {
            setLatitude(formData.get('latitude').toString());
        }
        if (formData.get('landmark')) {
            setLandmark(formData.get('landmark').toString());
        }
        if (formData.get('address')) {
            setAddress(formData.get('address').toString());
        }
        if (formData.get('locationIdStr')) {
            setShowSelectedLocation(formData.get('locationIdStr').toString());
            let selectedLocationId: any = parseInt(formData.get('locationIdStr').toString());
            let locationData: any = await locationApiService.getLocationById(selectedLocationId);
            setShowSelectedLocation(locationData.name);
            setShowSelectedPincode(locationData.pinCode)
            let existingSelectedLocation: any[] = [];
            existingSelectedLocation.push(locationData);
            formatLocationData(existingSelectedLocation);
            if (locationData.id == selectedLocationId) {
                let locationOptions: any[] = [];
                locationOptions.push(locationData);
                setSelectedLocation([...locationOptions]);
            }
        }
        if (formData.has('nearbyFacilityStr')) {
            let nearByFacilities: any[] = JSON.parse(formData.get('nearbyFacilityStr').toString());
            let highlightNearByFacilities: any[] = [];
            for (let i = 0; i < nearByFacilities.length; i++) {
                let nearByFacility: any = {};
                nearByFacility.propertyNearbyFacilityId = nearByFacilities[i].id;
                nearByFacility.nearbyFacilityId = parseInt(nearByFacilities[i].nearbyFacilityId);
                nearByFacility.metricUnit = nearByFacilities[i].metricUnit;
                nearByFacility.distance = nearByFacilities[i].distance;
                nearByFacility.facilityName = nearByFacilities[i].facilityName;
                highlightNearByFacilities.push(nearByFacility);
            }
            setNearByFacilities([...highlightNearByFacilities]);
        }
    }

    const previousPage = () => {
        onPreviousCallback(tabNumber - 1);
    };

    const updateUi = (data: any) => {
        setAddress(data.address);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
        setLandmark(data.landmark);
        setLocationData(data.locationData);
        setNearByFacilities(data.propertyNearbyFacilityData);
        highlightLocationFormData();
    }

    const searchLocation = async (q: any) => {
        console.log("START searchLocation()");
        console.log(q);
        setLocationSearchLoading(true);
        try {
            let data = await propertyApiService.searchLocation(q);
            formatLocationData(data);
        } catch (error) {
            console.log("ERROR searchLocation()");
            console.log(error);
        }
        setLocationSearchLoading(false);
        console.log("END searchLocation()");
    };

    const formatLocationData = (data: any) => {
        formatLocationDataResponse(data);
        setLocationData(data);
    }

   
    const formatLocationDataResponse = (data: any) => {
        for (let i = 0; i < data.length; i++) {
            data[i].nameLabel = data[i].name + ' | ' + data[i].pinCode + ' | ' + data[i].postOfficeName+' | ' + data[i].talukaName+  ' | ' + data[i].districtName+ ' | ' + data[i].abbreviation;
        }
    };

    const onLocationSelected = (e: any) => {
        console.log("START onLocationSelected()");
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedLocation(e);
        }
        setLocationValidationNameErrorMsg('');
        console.log("END onLocationSelected()");
    }

    const getLocationTemplatesData = async () => {
        try {
            let res = await propertyApiService.getLocationTemplatesData();
            setMetricUnit(res.metricUnit);
            setNearByFacilityData(res.nearbyFacilityData);
            highlightLocationFormData();

        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const addMoreNearbyFacility = () => {
        let nearByFacility: any = {
            'nearbyFacilityId': '',
            'distance': '',
            'metricUnit': ''
        };
        setNearByFacilities([...nearByFacilities, nearByFacility]);
    }

    const removeNearbyFacility = async (item: any, idx: number) => {
        try {
            if (item && item.propertyNearbyFacilityId) {
                await propertyApiService.deletePropertyNearByFacility(propertyId, item.propertyNearbyFacilityId);
            }
            let nearByFacilitiesArr = nearByFacilities.filter((item: any, j: any) => idx !== j);
            setNearByFacilities(nearByFacilitiesArr);

        } catch (error) {
            console.log("ERROR occured removeNearbyFacility() error=== " + JSON.stringify(error));
        }
    }

    const setNearbyDistanceValue = (idx: number, value: string) => {
        nearByFacilities[idx].distance = value;
        setNearByFacilities(nearByFacilities);
        setNearByFacilityDistenceValidationNameErrorMsg('');
    }

    const setNearbyFacilityName = (idx: number, name: string) => {
        nearByFacilities[idx].facilityName = name;
        setNearByFacilities(nearByFacilities);
        setNearByFacilityNameValidationNameErrorMsg('');
    }

    const handleNearbyFacilityChange = (idx: number, id: any) => {
        nearByFacilities[idx].nearbyFacilityId = id;
        setNearByFacilities(nearByFacilities);
        setNearByFacilityValidationNameErrorMsg('');
        setShowNearByOption(false);
    };
    const handleMetricUnitChange = (idx: number, value: any) => {
        nearByFacilities[idx].metricUnit = value;
        setNearByFacilities(nearByFacilities);
        setNearByFacilityMetricUnitValidationNameErrorMsg('');
        setShowMetricUnitOption(false);
    };

    const getNearbyFacilityTemplate = (idx: number, item: any) => {
        return (
            <div key={'nearByFacility_root_' + idx}>
                <div className="row gx-1">
                    <div className="col-sm-3 mt-4">
                        <select className={nearByFacilityValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                            defaultValue={item.nearbyFacilityId} onChange={(e) => handleNearbyFacilityChange(idx, e.target.value)}>
                            {showNearByOption && <option value="">Select</option>}
                            {nearByFacilityData.map((id: any, index: any) =>
                                (<option key={id.id} value={id.id}>{id.name}</option>))
                            }
                        </select>
                    </div>
                    <div className="col-sm-3 mt-4">
                        <input
                            className={nearByFacilityValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                            type="text"
                            placeholder="Facility Name"
                            defaultValue={item.facilityName}
                            onChange={e => { setNearbyFacilityName(idx, e.target.value) }} />
                    </div>
                    <div className="col-sm-2 mt-4">
                        <input
                            className={nearByFacilityValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                            type="number"
                            placeholder="Distance"
                            min={0}
                            defaultValue={item.distance}
                            onChange={e => { setNearbyDistanceValue(idx, e.target.value) }} />
                    </div>
                    <div className="col-sm-2 mt-4">
                        <select className='input-edit form-control mt-4'
                            defaultValue={item.metricUnit} onChange={(e) => handleMetricUnitChange(idx, e.target.value)}>
                            {showMetricUnitOption && <option value="">Select</option>}
                            <option value='KILOMETER'>Km</option>
                            <option value='FEET'>Ft</option>
                            <option value='METER'>M</option>
                        </select>
                    </div>
                    <div className="col-sm-1 mt-5 ">
                        <div className="mt-2">
                            <button className="btn btn-sm like_save" onClick={() => removeNearbyFacility(item, idx)}><CloseIcon /></button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    const setLatLog = async () => {

        try {
            let position: any = await getCurrentLocation();
            console.log(position)
            setLongitude(position.lng);
            setLatitude(position.lat);

        } catch (error) {
            console.error('Error retrieving location:', error);
        }

    };
    async function getCurrentLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            lat: String(position.coords.latitude),
                            lng: String(position.coords.longitude)
                        });
                    },
                    (error) => {
                        reject(error);
                    }
                );
            } else {
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
    }

    useEffect(() => {
        getLocationTemplatesData();
    }, []);

    return <>
        <div className="body container-fluid row">
            <div className=" container  w-100 ms-1">
                <div className="card-body">
                    <div className="col-sm-9 mx-auto ">
                        <div className="card ">
                            <div className="card-body">
                                <div className="text-color text-center">
                                <p> Location</p>
                                </div>
                                <div className="row ms-3">
                                    <div className="col-sm-6">
                                    <div className="mb-2">
                                            <label className="mt-4">Location<sup className="required">*</sup></label>
                                            <AsyncTypeahead
                                                id="basic-typeahead-single"
                                                className={locationValidationErrorMsg ? 'error-field ' : ''}
                                                labelKey="nameLabel"
                                                onSearch={(e) => searchLocation(e)}
                                                options={locationData}
                                                onChange={onLocationSelected}
                                                useCache={false}
                                                placeholder="Search Location"
                                                defaultSelected={selectedLocation}
                                                isLoading={locationSearchLoading}
                                                renderMenuItemChildren={renderMenuItemChildren}
                                                />
                                            <FormFieldError errorMessage={locationValidationErrorMsg}></FormFieldError>
                                            <label className="mt-4">Selected Location : </label> <span>{showSelectedLocation}|{showSelectedPinCode}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                    <div className="mb-2">
                                            <label className="mt-4">Address</label>
                                            <input
                                                className={addressValidationErrorMsg ? 'error-field input-box form-control' : 'input-edit form-control  mt-4'}
                                                type="text"
                                                value={address.trim() === "0" ? "" :address}
                                                onChange={(e) => { handleAddressOnChange(e.target.value) }}
                                                placeholder="Address"
                                            />
                                            <FormFieldError errorMessage={addressValidationErrorMsg}></FormFieldError>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row ms-3">
                                        <div className="col-sm-3">

                                        <div className="mb-2">
                                            <label className="mt-4">Landmark</label>
                                            <input
                                                className={landmarkValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                type="text"
                                                placeholder="Landmark"
                                                value={landmark.trim() === "0" ? "" :landmark}
                                                onChange={e => { handleLandMarkOnChange(e.target.value) }}
                                            />
                                            <FormFieldError errorMessage={landmarkValidationErrorMsg}></FormFieldError>
                                        </div>
                                        </div>
                                        <div className="col-sm-3">
                                        <div className="mb-2">
                                            <label className="mt-4">Latitude</label>
                                            <input
                                                className={latitudeValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                type="number"
                                                value={latitude.trim() === "0" ? "" :latitude}
                                                placeholder="Latitude"
                                                onChange={(e) => { handleLatitudeOnChange(e.target.value) }}
                                            />
                                            <FormFieldError errorMessage={latitudeValidationErrorMsg}></FormFieldError>
                                        </div>
                                        </div>
                                        <div className="col-sm-3">
                                        <div className="mb-2">
                                            <div>
                                                <label className="mt-4">Longitude</label>
                                                <input
                                                    className={longitudeValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    type="number"
                                                    placeholder="Longitude"
                                                    value={longitude.trim() === "0" ? "" :longitude}
                                                    onChange={(e) => { handleLongitudeOnChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={longitudeValidationErrorMsg}></FormFieldError>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-sm-2">
                                        <div className="location-icon-container">
                                            <label className="mt-4">Get current location</label>
                                            <GoLocation
                                                className="location-icon"
                                                size={40}
                                                title="current lat lng"
                                                onClick={setLatLog}
                                            />
                                        </div>
                                    </div>

                                    <div className="row ms-3">
                                <div className="col-sm-12">
                                    <div className="mb-2">
                                        <div className="mb-2">
                                    
                                                <label className="mt-4">Near By Facilities</label>
                                                <button onClick={(e) => addMoreNearbyFacility()} className="btn btn-md ms-5 rounded like_share" type="button">Add</button>
                                                {nearByFacilities.map((item: any, idx: any) => {
                                                    return (getNearbyFacilityTemplate(idx, item))
                                                })
                                                }
                                        </div>
                                        <FormFieldError errorMessage={nearByFacilityValidationErrorMsg}></FormFieldError>
                                    </div>
                                </div>
                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-4  d-flex justify-content-end me-5">
                                <button className="btn btn-primary me-3" onClick={previousPage}>Previous</button>
                                <button disabled={processing}  onClick={updateLocation} className="btn btn-warning me-3 " type="button">
                                        Update & Skip
                                        </button>
                                    {(processing)&& <Spinner animation="grow" variant="warning" />}
                                <button onClick={submitLocationFormData} className="btn btn-success " type="button">
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
}

export default EditLocationPage;