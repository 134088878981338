import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import PropertyApiService from "../../../../data/api/services/property/property-api-service";
import './edit-professionalpage-service-area.css';
import { FaSearch } from "react-icons/fa";
import ValidationError from "../../../../common/validation-error";
import FormFieldError from "../../../../components/form-field-error/form-field-error";
import LocationApiService from "../../../../data/api/services/location/location-api-service";
import ProfessionalPageServiceAreaImage from '../../../../assets/professional-page-3.jpeg';
import { Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { SaveLocationPayload } from "../../../../data/api/services/location/location-payload";
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';

const EditProfessionalPageServiceArea = ({ professionalPageId, professionalPageFormData, onPreviousCallback, onNextCallback }) => {

    const locationApiService = new LocationApiService();
    const tabNumber: number = 3;
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const propertyApiService = new PropertyApiService();
    const [locationData, setLocationData] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);
    const [showAddLocationDialog, setShowAddLocationDialog] = useState(false);
    const handleShowAddLocationDialog = () => setShowAddLocationDialog(true);
    const handleCloseAddLocationDialog = () => setShowAddLocationDialog(false);
    const [noResults, setNoResults] = useState(false);
    const [locationName, setLocationName] = useState("");
    const [locationNameValidationError, setLocationNameValidationError] = useState(false);
    const [pinCodeSearchLoading, setPinCodeSearchLoading] = useState(false);
    const [pinCodeData, setPinCodeData] = useState<any>([]);
    const [selectedPinCode, setSelectedPinCode] = useState<any>([]);
    const [pinCodeValidationErrorMsg, setPinCodeValidationErrorMsg] = useState(false);

    const preview = () => {
        onPreviousCallback(tabNumber - 1)
    };

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        setLocationData(data);
        if (q.length > 3 && data.length === 0) {
            setNoResults(true);
        } else {
            setNoResults(false);
        }
    };

    const highlightServiceAreaFormData = async () => {
        let formData: FormData = professionalPageFormData;
        if (!formData.has('serviceAreasStr')) {
            return;
        }
        let pageServiceAreas: any[] = JSON.parse(formData.get('serviceAreasStr').toString());
        let accumulatedLocations = [];
        for (let j = 0; j < pageServiceAreas.length; j++) {
            console.log('wesdrgvybhjnmkedrtfgyhjni', pageServiceAreas);
            const selectedLocationData = await locationApiService.getLocationById(pageServiceAreas[j].id);
            // const formattedLocation = `${selectedLocationData.name} | ${selectedLocationData.pinCode} | ${selectedLocationData.postOfficeName}    | ${selectedLocationData.districtName} | ${selectedLocationData.stateName}`;
            accumulatedLocations.push({ id: pageServiceAreas[j].id, formatedLocationData: selectedLocationData.formatedLocationData });
        }
        setSelectedLocations(accumulatedLocations);
    };

    const onLocationSelected = (selected: any) => {
        setSelectedLocations((prevLocations: any[]) => {
            const updatedLocations = [...prevLocations, ...selected];
            const uniqueLocations = Array.from(new Set(updatedLocations.map(location => location.id)))
                .map(id => updatedLocations.find(location => location.id === id));

            return uniqueLocations;
        });
    };

    const validateAndSaveServiceAreaFormData = () => {
        try {
            // validateServiceAreaFormData();
            if (professionalPageFormData.has('serviceAreasStr')) {
                professionalPageFormData.delete('serviceAreasStr');
            }
            professionalPageFormData.append('serviceAreasStr', JSON.stringify(selectedLocations));
            let serviceAreasStr = JSON.stringify(selectedLocations);
            console.log('serviceAreasStr', serviceAreasStr);
            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            throw error;
        }
    }

    const validateServiceAreaFormData = (): void => {
        let errors: any[] = [];
        const selectedLocation = JSON.stringify(selectedLocations);

        if (selectedLocation.length === 0) {
            let error: any = {};
            error.validationCode = "pageServiceAreas";
            error.message = 'Service Area required';
            errors.push(error);
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const removeLocation = (location: { id: any; }) => {
        setSelectedLocations((prevLocations: any[]) =>
            prevLocations.filter((prevLocation) => prevLocation.id !== location.id)
        );
    };

    const renderMenuItemChildren = (option, props, index) => {
        return (
            <div key={index}>
      <span style={{ color: 'black', marginRight: '5px' }}>{option.name}</span>
                <Tooltip title={option.isVerified ? 'Verified Location' : 'Location Not Verified'}>
                    <VerifiedIcon 
                        sx={{
                            color: option.isVerified ? '#0d6efd' : 'grey',  
                            fontSize: '15px', 
                        }} 
                    />
                </Tooltip>
                {' | '}
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName}</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.talukaName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.districtName}</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>
            </div>
        );
    };

    const handleLocationNameChange = (value: any) => {
        setLocationName(value);
        setLocationNameValidationError(false);
    };

    const searchPinCode = async (pinCode: any) => {
        setPinCodeSearchLoading(true);
        let data = await locationApiService.searchPinCode(pinCode);
        console.log(data);
        setPinCodeSearchLoading(false);
        setPinCodeData(data);
    };

    const onPinCodeSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedPinCode(e);
            setPinCodeValidationErrorMsg(e.length === 0);
        }
    };

    const clearSelectedPincode = () => {
        setSelectedPinCode([]);
    };

    const submitLocation = async () => {
        try {
            if (validateSaveLocation()) {
                let payload: SaveLocationPayload = {
                    name: locationName,
                    countryId: selectedPinCode[0].countryId,
                    stateId: selectedPinCode[0].stateId,
                    districtId: selectedPinCode[0].districtId,
                    talukaId: selectedPinCode[0].talukaId,
                    postOfficeId: selectedPinCode[0].postOfficeId,
                    pinCodeId: selectedPinCode[0].id,
                    locationPriorityId: '',
                    latitude: selectedPinCode[0].latitude,
                    longitude: selectedPinCode[0].longitude,
                    isVerified: false,
                    approvedBySuperAdmin: false
                };
                let data = await locationApiService.saveLocation(payload);
                console.log(data);
                const locationByIdData = await locationApiService.getLocationById(data.id);
                onLocationSelected([locationByIdData]);
                handleCloseAddLocationDialog();
                setLocationName("");
                setSelectedPinCode([]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validateSaveLocation = () => {
        let isFormValid = true;
        if (!locationName) {
            setLocationNameValidationError(true);
            isFormValid = false;
        }
        if (selectedPinCode.length == 0) {
            setPinCodeValidationErrorMsg(true);
            isFormValid = false;
        }
        return isFormValid;
    }

    const renderMenuForPinCode = (option, props, index) => {
        return (
            <div key={index}>
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName} (P.O.)</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.postOfficeData.talukName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.postOfficeData.talukaData.districtName} (Dist)</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.postOfficeData.talukaData.districtData.stateData.abbreviation}</span>
            </div>
        );
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        highlightServiceAreaFormData();
    }, []);

    return <>
        <section className="d-flex justify-content-center pb-0">
            <Row className="container">
                <Col lg={6} className="d-none d-lg-flex align-self-center">
                    <img src={ProfessionalPageServiceAreaImage} className='h-100 w-100' alt="google-bussiness" ></img>
                </Col>
                <Col lg={6} className="mt-3">
                    <Row className="g-3">
                        <Col lg={12} className="">
                            <h3 className="text-center">Where do you serve your customers? (optional)</h3>
                        </Col>
                        <Col lg={12} className="">
                            <span className='fs-5'>Add areas where your business provides deliveries or home and office visits. This will appear on your Business Profile.</span>
                        </Col>
                        {noResults && (
                            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ gap: '8px', color: '#0d6efd' }}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <span>No matching location found. <a onClick={handleShowAddLocationDialog}
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}>Add your location</a>
                                </span>
                            </Col>
                        )}
                        <Col lg={12} className="">
                            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                                <div className="search-icon">
                                    <FaSearch />
                                </div>
                                <div className="w-100">
                                    <AsyncTypeahead
                                        className={`${locationValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                        id="basic-typeahead-multi"
                                        labelKey="formatedLocationData"
                                        onSearch={(e: any) => searchLocation(e)}
                                        options={locationData}
                                        onChange={onLocationSelected}
                                        useCache={false}
                                        placeholder="Search Locations"
                                        multiple
                                        isLoading={locationSearchLoading}
                                        selected={selectedLocations}
                                        renderMenuItemChildren={renderMenuItemChildren} />
                                    <FormFieldError errorMessage={locationValidationErrorMsg}></FormFieldError>
                                </div>
                            </div>
                            <div className="selected-locations">
                                {selectedLocations.map((location) => (
                                    <div key={location.id} className="location-chip">
                                        {location.formatedLocationData}
                                        <span className="remove-icon" onClick={() => removeLocation(location)}>
                                            x
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <hr />
                        </Col>
                        <Col lg={12}>
                            <div className="d-flex justify-content-between align-items-center p-3">
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={preview}
                                    >
                                        Previous
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="btn btn-success"
                                        type="button"
                                        onClick={validateAndSaveServiceAreaFormData}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal show={showAddLocationDialog} onHide={handleCloseAddLocationDialog} backdrop="static" size="lg">
                <Modal.Header className="fw-bold" closeButton>
                    <Modal.Title>Add your location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3">
                        <Col lg={12}>
                            <div className={`custom-input-field ${locationNameValidationError ? 'is-invalid' : ''}`}>
                                <input type="text"
                                    placeholder=" "
                                    value={locationName}
                                    onChange={(e) => { handleLocationNameChange(e.target.value) }} />
                                <span className="fw-bold custom-input-field-placeholder">Enter your location</span>
                            </div>
                            {locationNameValidationError && <div className="invalid-feedback px-2 m-0">Location name required</div>}
                        </Col>
                        <Col lg={12}>
                            <div>
                                <div className={`custom-input-field async-container d-flex justify-content-end align-items-center ${pinCodeValidationErrorMsg ? 'is-invalid' : ''}`}>
                                    <Col className="close-icon align-items-center d-flex">
                                        {selectedPinCode.length > 0 && (
                                            <FontAwesomeIcon icon={faTimes} onClick={clearSelectedPincode} />
                                        )}
                                    </Col>
                                    <Col>
                                        <AsyncTypeahead
                                            className="rbt-input-wrapper"
                                            id="select-pincode"
                                            labelKey="pinCode"
                                            onSearch={(e) => searchPinCode(e)}
                                            options={pinCodeData}
                                            onChange={onPinCodeSelected}
                                            useCache={false}
                                            placeholder=""
                                            defaultSelected={selectedPinCode}
                                            isLoading={pinCodeSearchLoading}
                                            selected={selectedPinCode}
                                            minLength={4}
                                            renderMenuItemChildren={renderMenuForPinCode}
                                        />
                                        <span className="fw-bold custom-input-field-placeholder">PinCode</span>
                                    </Col>
                                </div>
                                {pinCodeValidationErrorMsg && <div className="invalid-feedback px-2">PinCode required</div>}
                            </div>
                        </Col>
                        <div className="d-flex flex-column flex-lg-row justify-content-end" style={{ gap: '10px' }}>
                            <button className="btn btn-danger" onClick={handleCloseAddLocationDialog}>Cancel</button>
                            <button className="btn btn-success" onClick={submitLocation}>Submit</button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
    </>

}
export default EditProfessionalPageServiceArea;